const timeline = [
  {
    year:'2019',
    data:[{
      type:'left',
      time:'7月',
      title:'产品战略发布会，引领护肤新趋势',
      desc:'持续两年时间专研,推出升级祛痘产品<br/>行业内率先提出小分子概念并举行发布会',
      img:require('@/assets/images/banzhuan/progress/2019_1.jpg')
    },
    {
      type:'right',
      time:'8月',
      title:'品牌备受瞩目，受邀参加《美丽俏佳人》',
      desc:'羽素星品净痘点涂凝胶,寡肽修护套盒,<br/>蓝铜胜肽套装等引发追捧',
      img:require('@/assets/images/banzhuan/progress/2019_2.jpg')
    }]
  },
  {
    year:'2020',
    data:[{
      type:'right',
      time:'3月',
      title:'官方盖章认证',
      desc:'荣获“315消费者可信赖产品”',
      img:require('@/assets/images/banzhuan/progress/2020_11.png')
    },
    {
      type:'left',
      time:'6月',
      title:'千人口碑出圈',
      desc:'千人测评种草<br/>掀起小红书热推浪潮',
      img:require('@/assets/images/banzhuan/progress/2020_2.jpg')
    },
    {
      type:'right',
      time:'6月',
      title:'品牌LOGO焕新',
      desc:'持续深化核心价值理念<br/>全新LOGO升级',
      img:require('@/assets/images/banzhuan/progress/2020_3.png')
    },
    {
      type:'left',
      time:'6月',
      title:'携手新秀综艺《阳光姐妹淘》',
      desc:'蒋梦婕分享护肤神器<br/>羽素寡肽修复套盒人气飙升',
      img:require('@/assets/images/banzhuan/progress/2020_4.jpg')
    },
    {
      type:'right',
      time:'8月',
      title:'羽素肌肤健康科学研究中心正式成立',
      desc:'2大问题肌肤研究中心（痘肌、敏感肌)<br/>+1个功效测评中心<br/>+1个AI智能大数据测肤系统<br/>+1大客户服务中心(痘肌护肤顾问+敏肌护肤顾问)<br/>+拥有强大的智慧团——[科学顾问团]出谋划策<br/>为品牌产品与服务升级提供强有力支撑',
      img:require('@/assets/images/banzhuan/progress/2020_5.jpg')
    },
    {
      type:'left',
      time:'8月',
      title:'牵手美妆综艺《我是大美人》',
      desc:'乐乐团长等嘉宾强势安利<br/>羽素胶态硫,纤连蛋白祛痘精华液惊艳全场',
      img:require('@/assets/images/banzhuan/progress/2020_6.jpg')
    },
    {
      type:'right',
      time:'8月',
      title:'国潮好物 跨界出圈',
      desc:'羽素X国宝《永乐宫》绝版演绎国潮力',
      img:require('@/assets/images/banzhuan/progress/2020_7.jpg')
    },
    {
      type:'left',
      time:'8月',
      title:'香港艺人薛凯琪上任<br/>【羽素推荐官】',
      desc:'力荐羽素寡肽修护套盒',
      img:require('@/assets/images/banzhuan/progress/2020_8.jpg')
    },
    {
      type:'right',
      time:'8月',
      title:'与国内行业标杆企业战略合作,共创实验室',
      desc:'羽素 X 皓雨集团达成战略合作<br/>实现产品功效力升级',
      img:require('@/assets/images/banzhuan/progress/2020_9.jpg')
    },
    {
      type:'left',
      time:'10月',
      title:'荣登顶级杂志《昕薇》《伊周》',
      desc:'羽素纤连蛋白祛痘精华液备受青睐',
      img:require('@/assets/images/banzhuan/progress/2020_10.jpg')
    },
    {
      type:'right',
      time:'10月',
      title:'台湾艺人汪东城好物分享',
      desc:'永乐宫 X 羽素舒缓修护喷雾爆品来袭',
      img:require('@/assets/images/banzhuan/progress/2020_11.jpg')
    },
    {
      type:'left',
      time:'12月',
      title:'荣膺行业TOP榜',
      desc:'羽素荣登2020年新浪时尚新美力美妆榜',
      img:require('@/assets/images/banzhuan/progress/2020_12.jpg')
    },
    {
      type:'right',
      time:'12月',
      title:'创立联合实验室',
      desc:'羽素与基因工程药物国家工程研究中心共同建立<br/>“敏感肌痘肌联合实验室”',
      img:require('@/assets/images/banzhuan/progress/2020_13.jpg')
    },
  ]
  },
  {
    year:'2021',
    data:[{
      type:'left',
      time:'2月',
      title:'时尚圣典《Vogue》点赞国潮跨界之作',
      desc:'羽素 X 《永乐宫》祛痘联名套盒',
      img:require('@/assets/images/banzhuan/progress/2021_1.jpg')
    },
    {
      type:'right',
      time:'4月',
      title:'羽素VI焕新升级<br/>星品闪耀亮相',
      desc:'星品焕新升级 闪耀亮相 引发围观',
      img:require('@/assets/images/banzhuan/progress/2021_22.png')
    },
    {
      type:'left',
      time:'6月',
      title:'《品牌中国》重点推荐',
      desc:'荣获央视栏目《品牌中国》“重点推荐品牌”',
      img:require('@/assets/images/banzhuan/progress/2021_33.png')
    }]
  },
]



export default timeline
