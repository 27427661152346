<template>
  <div class="progress_page">
    <div class="banner">
      <img src="@/assets/images/banzhuan/progress/banner.jpg" alt="">
    </div>
    <div class="page_details">
      <div class="timeline" v-for="(item,index) in timeline" :key="index"  v-if="year === item.year">
        <div class="year_content">
          <div class="year">{{item.year}}</div>
          <div class="item" v-for="(el,i) in item.data" :key="i" >
            <div class="flex" v-if="el.type === 'left'">
              <div class="item_left">
                <img :src="el.img" alt="">
              </div>
              <div class="item_right">
                <div class="time">{{el.time}}</div>
                <div class="title" v-html="el.title"></div>
                <div class="desc" v-html="el.desc"></div>
              </div>
            </div>
            <div class="flex" v-else>
              <div class="item_left">
                <div class="time">{{el.time}}</div>
                <div class="title" v-html="el.title"></div>
                <div class="desc" v-html="el.desc"></div>
              </div>
              <div class="item_right">
                <img :src="el.img" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import timeline from './progressData'
import './progress.scss'
export default {
  name: 'Progress',
  components: {
  },
  data:function(){
    return {
      year:'2021',
      timeline:timeline
    }
  },
  methods: {
  },
}
</script>
<style scoped lang='scss'>
.progress_page .page_details .timeline .item .desc{
  font-size: 20px;
}
</style>
